import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Dropdown,
  DropdownProps,
  Input,
  InputOnChangeData,
  InteractionTag,
  InteractionTagPrimary,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Option,
  Overflow,
  OverflowItem,
  Tag,
  TagGroup,
  TagGroupProps,
  Text,
  Tooltip,
  makeStyles,
  shorthands,
  tagClassNames,
  tokens,
  useIsOverflowItemVisible,
  useOverflowMenu,
} from '@fluentui/react-components'
import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { MAX_GROUP_MEMBER_COUNT } from '../constants'
import { useStreams } from '../hooks/useStreams'
import { useCurrentOrganization } from '../providers/CurrentOrganizationProvider'
import { useNotificationsClient } from '../providers/NotificationsProvider'
import { Bearer, Group } from '../util/group'
import { GroupDialogState } from './StreamList'

const useStyles = makeStyles({
  menuItem: {
    padding: tokens.spacingHorizontalXS,
    '@media(hover: hover) and (pointer: fine)': {
      ':hover': {
        [`& .${tagClassNames.root}`]: {
          color: tokens.colorNeutralForeground2Hover,
        },
      },
    },
  },
  tag: {
    backgroundColor: 'transparent',
    ...shorthands.borderColor('transparent'),
  },
  tagName: {
    maxWidth: '15ch',
    display: 'block',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
  dialogBody: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalL,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalL,
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalS,
  },
  label: {
    width: 'fit-content',
  },
  dialogActions: {
    justifyContent: 'end',
  },
})

// NOTE Because the types shown in the documentation for the onOptionSelect
// handler is not exported we need to use DropdownProps. This also removes
// undefined from the allowed types.
export type DropdownSelectHandler = Exclude<
  DropdownProps['onOptionSelect'],
  undefined
>

type EditGroupDialogProps = {
  setOpenState: React.Dispatch<React.SetStateAction<GroupDialogState>>
  onEditGroup: (group: Group) => void
  group: Group
}

type OverflowItemsProps = {
  selectedBearers: Map<string, Bearer>
}

type OverflowMenuProps = {
  selectedBearers: Map<string, Bearer>
}

type OverflowMenuItemProps = {
  bearer: Bearer
}

export function EditGroupDialog({
  setOpenState,
  onEditGroup,
  group,
}: EditGroupDialogProps) {
  const styles = useStyles()
  const { t } = useTranslation('streams')
  const organizationId = useCurrentOrganization().id
  const { client: notificationsClient } = useNotificationsClient()
  const streams = useStreams({
    organizationId,
    webPubClient: notificationsClient,
  })
  const [groupName, setGroupName] = useState(group.name ?? '')
  const [selectedBearers, setSelectedBearers] = useState<Map<string, Bearer>>(
    new Map(group.bearers)
  )
  // Since previously selected bearers might no longer exist, i.e. have streamed
  // within the last 24h (retention time), they need to be added to the list of selectable
  // bearers.
  const bearers: Map<string, Bearer> = new Map(selectedBearers)
  const removeItem: TagGroupProps['onDismiss'] = (_e, { value }) => {
    setSelectedBearers((previousSelectedBearers) => {
      const selectedBearers = new Map(previousSelectedBearers)
      selectedBearers.delete(value)

      return selectedBearers
    })
  }

  for (const stream of streams || []) {
    bearers.set(stream.bearerId, {
      id: stream.bearerId,
      name: stream.metadata?.bearerName || '',
    })
  }

  // Can't use function statement for this since the types are not exported
  const handleSelectBearer: DropdownSelectHandler = (_event, data) => {
    const selectedBearers: Map<string, Bearer> = new Map()

    for (const bearerId of data.selectedOptions) {
      const bearer = bearers.get(bearerId)
      if (bearer) {
        selectedBearers.set(bearerId, bearer)
      }
    }
    setSelectedBearers(selectedBearers)
  }

  const bearersExist = bearers.size !== 0

  return (
    <Dialog
      open={true}
      onOpenChange={(_, data) => {
        setOpenState({ openDialog: data.open ? 'edit' : null, group: group })
      }}
    >
      <DialogSurface>
        <form>
          <DialogBody className={styles.dialogBody}>
            <DialogTitle>Edit group</DialogTitle>
            <DialogContent className={styles.dialogContent}>
              <div className={styles.field}>
                <label className={styles.label} htmlFor="group-name">
                  Group name
                </label>
                <Input
                  id="group-name"
                  onChange={handleNameChange}
                  value={groupName}
                />
              </div>
              <div className={styles.field}>
                <label className={styles.label} htmlFor="group-bearers">
                  Camera users
                </label>
                {selectedBearers.size !== 0 && (
                  <Overflow>
                    <TagGroup onDismiss={removeItem} aria-label="User tags">
                      <OverflowItems selectedBearers={selectedBearers} />
                      <OverflowMenu selectedBearers={selectedBearers} />
                    </TagGroup>
                  </Overflow>
                )}
                <Tooltip
                  relationship={'description'}
                  content={bearersExist ? '' : t('no-one-streaming')}
                >
                  <Dropdown
                    id="group-bearers"
                    placeholder={
                      bearersExist
                        ? 'Type to search'
                        : 'No camera users available'
                    }
                    disabled={!bearersExist}
                    multiselect={true}
                    name="users"
                    value=""
                    selectedOptions={[...selectedBearers.keys()]}
                    onOptionSelect={handleSelectBearer}
                  >
                    {[...bearers.values()].map((bearer) => (
                      <Option
                        key={bearer.id}
                        disabled={shouldOptionBeDisabled(
                          selectedBearers,
                          bearer.id
                        )}
                        value={bearer.id}
                      >
                        {bearer.name}
                      </Option>
                    ))}
                  </Dropdown>
                </Tooltip>
              </div>
            </DialogContent>
            <DialogActions className={styles.dialogActions}>
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary">Cancel</Button>
              </DialogTrigger>
              <DialogTrigger disableButtonEnhancement>
                <Button
                  onClick={handleClick}
                  type="submit"
                  appearance="primary"
                >
                  Save
                </Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  )

  // TODO: Handle selected bearer no longer existing (retention time expired).

  function handleNameChange(
    _event: ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) {
    setGroupName(data.value)
  }

  // Since the edit group dialog is conditionally rendered, it is not present in
  // the DOM once open state changes. Form submission occurs after dialog open
  // change event. This results in the form submission being called on a non
  // existing form and the event is therefor cancelled. In order to retain the
  // desired form behavior, primary action button on enter etc, the form should
  // remain. However, the submit logic should instead be called on primary
  // action button click.
  function handleClick() {
    onEditGroup({
      id: group.id,
      name: groupName,
      bearers: selectedBearers,
    })
    setOpenState({ openDialog: null, group: group })
  }
}

function shouldOptionBeDisabled(
  selectedBearers: Map<string, Bearer>,
  bearerId: string
) {
  if (
    selectedBearers.size < MAX_GROUP_MEMBER_COUNT ||
    selectedBearers.get(bearerId)
  ) {
    return false
  }
  return true
}

function OverflowItems({ selectedBearers }: OverflowItemsProps) {
  const styles = useStyles()

  return [...selectedBearers.values()].map((bearer) => (
    <OverflowItem key={bearer.id} id={bearer.id}>
      <Tag
        id={bearer.id}
        dismissible
        dismissIcon={{ 'aria-label': 'remove' }}
        size="small"
        shape="circular"
        value={bearer.id}
        key={bearer.id}
      >
        <Text className={styles.tagName}>{bearer.name}</Text>
      </Tag>
    </OverflowItem>
  ))
}

/**
 * A menu for viewing tags that have overflowed and are not visible.
 */
function OverflowMenu({ selectedBearers }: OverflowMenuProps) {
  const { ref, isOverflowing, overflowCount } =
    useOverflowMenu<HTMLButtonElement>()

  if (!isOverflowing) {
    return null
  }

  return (
    <InteractionTag size="small" shape="circular">
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <InteractionTagPrimary
            // Some strange bug is causing redirect on click.
            onClick={(event) => event.preventDefault()}
            ref={ref}
            aria-label={`${overflowCount} more users`}
          >
            {`+${overflowCount}`}
          </InteractionTagPrimary>
        </MenuTrigger>
        <MenuPopover>
          <MenuList>
            {[...selectedBearers.values()].map((bearer) => (
              <OverflowMenuItem key={bearer.id} bearer={bearer} />
            ))}
          </MenuList>
        </MenuPopover>
      </Menu>
    </InteractionTag>
  )
}

/**
 * A menu item for an overflow menu that only displays when the tab is not
 * visible.
 */
function OverflowMenuItem({ bearer }: OverflowMenuItemProps) {
  const styles = useStyles()
  const isVisible = useIsOverflowItemVisible(bearer.id)

  if (isVisible) {
    return null
  }

  return (
    <MenuItem key={bearer.id} className={styles.menuItem}>
      <Tag
        className={styles.tag}
        dismissible
        dismissIcon={{ 'aria-label': 'remove' }}
        size="small"
        value={bearer.id}
        key={bearer.id}
      >
        <Text className={styles.tagName}>{bearer.name}</Text>
      </Tag>
    </MenuItem>
  )
}
