import { getXYFromLatLon } from '@axteams-one/bws-cloud-maps'
import { Position } from '@axteams-one/bws-cloud-maps/layers/tracking'
import {
  Caption1,
  Caption2,
  makeStyles,
  tokens,
} from '@fluentui/react-components'
import { formatDistance } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { bearerIdFromSubject, streamFromSubject } from '../../util/map'
import { comparePositionsLatitudeDescending } from '../../util/sorting'
import { Stream } from '../../util/stream'
import Label from './Label'

const useStyles = makeStyles({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  lastPositionLabel: {
    color: tokens.colorNeutralForeground3,
  },
})

// Vertical offset between position marker and label, in px. Radius of marker
// (6) + padding (2) = 8
const Y_OFFSET = -8

type LabelsProps = {
  positions: Position[]
  streams: Stream[]
  selectedBearers: string[]
  hoveredBearers: string[]
  onClick: (subject: string) => void
  onHover: (subject?: string) => void
  bounds: google.maps.LatLngBounds | undefined
  projection: google.maps.Projection | undefined
  zoom: number
  inactiveBearers: string[]
}

function Labels({
  positions,
  streams,
  inactiveBearers,
  selectedBearers,
  hoveredBearers,
  onClick,
  onHover,
  bounds,
  projection,
  zoom,
}: LabelsProps) {
  // Sort positions based on latitude in descending order, i.e. render the label
  // of the most southern position last (on top).
  const sortedPositions = [...positions].sort(
    comparePositionsLatitudeDescending
  )

  const styles = useStyles()
  const { t } = useTranslation('streams')

  return sortedPositions.map((position) => {
    const stream = streamFromSubject(position.subject, streams)
    if (!stream) {
      return null
    }

    const mapCanvasPosition = getXYFromLatLon(
      [position.latitude, position.longitude],
      bounds,
      projection,
      zoom
    )
    if (!mapCanvasPosition) {
      return null
    }

    mapCanvasPosition[1] += Y_OFFSET

    const hovered = hoveredBearers.includes(stream.bearerId)
    const selected = selectedBearers.includes(stream.bearerId)
    const inactive = inactiveBearers.includes(
      bearerIdFromSubject(position.subject)
    )

    const bearerName = stream.metadata.bearerName
    const textLabel =
      formatTextLabel(bearerName, selected, hovered, inactive) ?? t('common:na')

    return (
      <Label
        key={position.subject}
        subject={position.subject}
        position={mapCanvasPosition}
        selected={selected}
        hovered={hovered}
        onClick={onClick}
        onHover={onHover}
      >
        <div
          className={styles.contentContainer}
          data-testid={bearerName + 'Label'}
        >
          <Caption1>{textLabel}</Caption1>
          {inactive && (
            <Caption2 className={styles.lastPositionLabel}>
              {t('last-position-ago', {
                duration: formatDistance(
                  new Date(position.localTime),
                  new Date()
                ),
              })}
            </Caption2>
          )}
        </div>
      </Label>
    )
  })
}

function formatTextLabel(
  bearerName: string | undefined,
  selected: boolean,
  hovered: boolean,
  inactive: boolean
): string | undefined {
  if (!bearerName) {
    return undefined
  }

  if (hovered || inactive || selected) {
    return bearerName
  }

  return bearerName
    .split(' ')
    .map((word) => word[0])
    .join('')
    .toUpperCase()
}

export default Labels
