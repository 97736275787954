import {
  NoMatchDark,
  NoMatchLight,
} from '@axiscommunications/fluent-illustrations'
import { useFlag } from '@axteams-one/bws-cloud-flags/react'
import { Position } from '@axteams-one/bws-cloud-maps/layers/tracking'
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Body2,
  makeStyles,
  tokens,
} from '@fluentui/react-components'
import { Temporal } from '@js-temporal/polyfill'
import { DragEvent, MouseEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useThemeId } from '../providers/ThemeProvider'
import { Flags } from '../util/flags'
import { Group, getCachedPinnedIds, setCachedPinnedIds } from '../util/group'
import { subjectFromBearerId } from '../util/map'
import { Stream } from '../util/stream'
import { EditGroupDialog } from './EditGroupDialog'
import GroupItem from './GroupItem'
import { RemoveGroupDialog } from './RemoveGroupDialog'
import StreamItem from './StreamItem'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    // Style selected stream item
    '& .active': {
      backgroundColor: tokens.colorNeutralBackground1Selected,
      '& span': {
        color: tokens.colorNeutralForeground1,
      },
    },
    overflow: 'auto',
  },
  accordionHeader: {
    '& > button': { paddingInline: 0 },
  },
  accordionPanel: {
    marginInlineEnd: 0,
  },
  pinnedList: {
    marginBlockEnd: tokens.spacingVerticalM,
  },
  noMatchesContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingBlock: tokens.spacingVerticalXL,
  },
  noMatchesText: {
    color: tokens.colorNeutralForegroundDisabled,
  },
})

type StreamListProps = {
  streams: Stream[]
  positions: Position[]
  groups: Group[]
  selectedGroupId: string | null
  hoveredStreamId: string | null
  onGroupMapButtonClick: (groupId: string) => void
  onGroupClick: (event: MouseEvent<HTMLDivElement>, groupId: string) => void
  onGroupPointerOver: (group: Group) => void
  onEditGroup: (group: Group) => void
  onRemoveGroup: (group: Group) => void
  onStreamMapButtonClick: (stream: Stream) => void
  onStreamClick: (event: MouseEvent<HTMLAnchorElement>, stream: Stream) => void
  onStreamPointerOver: (stream: Stream) => void
  onStreamDrag: (event: DragEvent<HTMLAnchorElement>, stream: Stream) => void
  onGroupDrop: (groupId: string) => void
  onStreamDrop: (stream: Stream) => void
  onPointerLeave: () => void
}

type openDialog = 'edit' | 'remove' | null

export interface GroupDialogState {
  openDialog: openDialog
  group: Group
}

export enum ItemType {
  stream,
  group,
}

interface Item {
  id: string
  type: ItemType
  object: Stream | Group
  pinned?: boolean
  triggerTimestamp?: Temporal.ZonedDateTime
}

export function StreamList({
  streams,
  positions,
  groups,
  selectedGroupId,
  hoveredStreamId,
  onGroupMapButtonClick,
  onGroupClick,
  onGroupPointerOver,
  onEditGroup,
  onRemoveGroup,
  onStreamMapButtonClick,
  onStreamClick,
  onStreamPointerOver,
  onStreamDrag,
  onGroupDrop,
  onStreamDrop,
  onPointerLeave,
}: StreamListProps) {
  const styles = useStyles()
  const [themeId] = useThemeId()
  const { t } = useTranslation('streams')
  const enablePinGroups = useFlag(Flags.PIN_GROUPS)?.enabled === true
  const [pinnedIds, setPinnedIds] = useState(() => getCachedPinnedIds())
  const [showPinned, setShowPinned] = useState(
    localStorage.getItem('showPinned') === 'true'
  )

  const [dialogState, setDialogState] = useState<GroupDialogState>({
    openDialog: null,
    group: groups[0],
  })

  if (streams?.length === 0 && groups.length === 0) {
    return (
      <div className={styles.noMatchesContainer}>
        {themeId === 'light' ? (
          <NoMatchLight width={120} />
        ) : (
          <NoMatchDark width={120} />
        )}
        <Body2 className={styles.noMatchesText}>{t('common:no-matches')}</Body2>
      </div>
    )
  }

  const items: Item[] = []
  const pinnedItems: Item[] = []

  for (const group of groups) {
    const pinned = pinnedIds.includes(group.id)

    const groupItem = {
      id: group.id,
      type: ItemType.group,
      object: group,
      pinned: pinned,
      triggerTimestamp: group.triggerTimestamp,
    }

    if (pinned && enablePinGroups) {
      pinnedItems.push(groupItem)
    } else {
      items.push(groupItem)
    }
  }

  for (const stream of streams) {
    const streamItem = {
      id: stream.id,
      type: ItemType.stream,
      object: stream,
      triggerTimestamp: stream.metadata.triggerTimestamp,
    }

    items.push(streamItem)
  }

  pinnedItems.sort(sortDescOnTimestamp)
  items.sort(sortDescOnTimestamp)

  return (
    <div className={styles.container} onPointerLeave={onPointerLeave}>
      {pinnedItems.length > 0 && (
        <Accordion
          collapsible
          openItems={showPinned && ['pinnedItems']}
          onToggle={handleTogglePinnedItems}
        >
          <AccordionItem value="pinnedItems">
            <AccordionHeader className={styles.accordionHeader} size="small">
              {t('pinned-groups')}
            </AccordionHeader>
            <AccordionPanel className={styles.accordionPanel}>
              <div className={styles.pinnedList}>
                {pinnedItems?.map(itemMapper)}
              </div>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}
      {items?.map(itemMapper)}
      {renderDialog()}
    </div>
  )

  function renderDialog() {
    switch (dialogState.openDialog) {
      case 'edit':
        return (
          <EditGroupDialog
            setOpenState={setDialogState}
            onEditGroup={onEditGroup}
            group={dialogState.group}
          />
        )
      case 'remove':
        return (
          <RemoveGroupDialog
            setOpenState={setDialogState}
            onRemoveGroup={onRemoveGroup}
            group={dialogState.group}
          />
        )
      default:
        return null
    }
  }

  function itemMapper(item: Item) {
    if (item.type === ItemType.stream) {
      const stream = item.object as Stream
      const position = positions.find(
        (position: Position) =>
          position.subject === subjectFromBearerId(stream.bearerId)
      )

      return (
        <StreamItem
          key={stream.id}
          stream={stream}
          position={position}
          hovered={stream.id === hoveredStreamId}
          onMapButtonClick={onStreamMapButtonClick}
          onClick={onStreamClick}
          onPointerOver={onStreamPointerOver}
          onDrop={onStreamDrop}
          onDrag={onStreamDrag}
        />
      )
    }

    const group = item.object as Group

    return (
      <GroupItem
        key={group.id}
        group={group}
        positions={positions}
        selected={group.id === selectedGroupId}
        pinned={item.pinned}
        triggerTimestamp={group.triggerTimestamp}
        setDialogState={setDialogState}
        onMapButtonClick={onGroupMapButtonClick}
        onClick={onGroupClick}
        onPointerOver={onGroupPointerOver}
        onPin={handlePin}
        onDrop={onGroupDrop}
      />
    )
  }

  function handlePin(id: string) {
    const updatedPinnedIds = getCachedPinnedIds()
    const pinned = updatedPinnedIds.includes(id)
    if (pinned) {
      setAndCachePinnedIds(
        updatedPinnedIds.filter((pinnedId) => pinnedId !== id)
      )
    } else {
      setAndCachePinnedIds([...updatedPinnedIds, id])
    }
  }

  function setAndCachePinnedIds(ids: string[]) {
    setPinnedIds(ids)
    setCachedPinnedIds(ids)
  }

  function handleTogglePinnedItems() {
    setShowPinned((previous) => {
      localStorage.setItem('showPinned', (!previous).toString())

      return !previous
    })
  }
}

function sortDescOnTimestamp(a: Item, b: Item): number {
  // Prioritize valid trigger timestamp over undefined.
  if (!a.triggerTimestamp || !b.triggerTimestamp) {
    const aCompareValue = a.triggerTimestamp ? 1 : 0
    const bCompareValue = b.triggerTimestamp ? 1 : 0
    return bCompareValue - aCompareValue
  }
  return Temporal.ZonedDateTime.compare(b.triggerTimestamp, a.triggerTimestamp)
}
