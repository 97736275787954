import { BodyWornCloud20Regular } from '@axiscommunications/fluent-icons'
import { useWebApplication } from '@axteams-one/bws-cloud-discovery/react'
import {
  ApplicationOption,
  Language,
  LanguageCode,
  PopulatedTopbarProvider,
  usePopulatedTopbarValues,
} from '@axteams-one/populated-topbar'
import React, { ReactElement, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { config } from '../config'
import i18n from '../i18n/i18n'
import { MySystemsApplicationId } from '../util/MySystemsApplicationId'
import { setCachedGroups } from '../util/group'
import { useThemeId } from './ThemeProvider'

type TopBarProviderProps = {
  children: ReactElement
}

export const TopbarProvider = ({
  children,
}: TopBarProviderProps): ReactElement => {
  const navigate = useNavigate()
  const [_, setThemeId] = useThemeId()

  const onLanguageChanged = useCallback((lang: Language) => {
    // TODO Support for right->left languages
    i18n.changeLanguage(lang)
  }, [])

  const [organizationInitiated, setOrganizationInitiated] = useState(false)

  const env = config.environment === 'prod' ? 'prod' : 'stage'
  // local storage key to provide feature flag-like behaviour, as we don't
  // have access to the flagsmith provider in the current scope.
  const disableDrawer =
    localStorage.getItem('flag_ui_enable_my_systems_drawer') === null

  return (
    <PopulatedTopbarProvider
      env={env}
      region="eu"
      acxApiKey={config.acxClientKey}
      applicationLubalinPath="/LubalinGraphECG-Demi.woff2"
      myApplication={{
        id: 'AXIS Body Worn Live',
        label: 'AXIS Body Worn Live',
        group: 'CUSTOM',
        // Send in a custom icon for our application. Needs to removed when we
        // are in MySystems as they provide the icon for us
        icon: <BodyWornCloud20Regular />,
      }}
      onLanguageChanged={onLanguageChanged}
      onOrganizationChanged={handleOrganizationChanged}
      onThemeChanged={setThemeId}
      supportedLanguages={[LanguageCode.English]}
      // Override the MySystems application drawer, to disable it,
      // if you haven't set the flag.
      temporaryForceApplicationMenuWithoutColor={disableDrawer}
      // Override the available applications, to make sure that you cannot
      // browse to other applications in MySystems. Needs to removed when we are
      // in MySystems
      applicationOptions={disableDrawer ? [] : undefined}
    >
      {children}
    </PopulatedTopbarProvider>
  )

  function handleOrganizationChanged() {
    // Prevent initial redirect
    if (!organizationInitiated) {
      setOrganizationInitiated(true)
      return
    }
    // Groups are specific to an organization and should therefor be reset on
    // organization change.
    setCachedGroups(new Map())

    // If a user switches organization we need to navigate them back to the
    // root of the page removing any state which might no longer be valid
    const destination = location.pathname.startsWith('/settings')
      ? '/settings'
      : '/'
    navigate(destination)
  }
}

/**
 * Returns an url populated with the parameters needed to go from different
 * applications in mySystems, while keeping the
 * selected organization, theme and other parameters.
 * @param applicationId the id of the application you want to get
 */
export function useGetPopulatedTopbarUrl(applicationId: string) {
  const { getUrlWithTopbarParameters } = usePopulatedTopbarValues()

  // Since we have disabled the MySystems drawer, and overridden what
  // applications are available, this also disabled the getPopulatedUrl as no
  // applications exist. Therefore, we have to manually supply what applications
  // exist, so the links to licenseManager and IAM still work, with topbar
  // parameters.
  // Needs to be removed when we are in MySystems
  const lm: ApplicationOption = {
    id: MySystemsApplicationId.LICENSE,
    url: useWebApplication('license-manager')?.uri ?? '',
  }
  const iam: ApplicationOption = {
    id: MySystemsApplicationId.IAM,
    url: useWebApplication('iam-portal')?.uri ?? '',
  }
  const applicationList = [lm, iam]

  const applicationUrl =
    applicationList?.find((app) => app.id === applicationId)?.url ?? undefined

  return applicationUrl ? getUrlWithTopbarParameters(applicationUrl) : ''
}
